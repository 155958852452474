import React, { useState } from "react";
import logo from "../../assets/images/header/logo3.png";
import "../../assets/style/header.css";
import { NavLink, useNavigate } from "react-router-dom";
export const Header = () => {
  const [toggle, setToggle] = useState("");
  const navText = [
    { lable: "HOME", to: "/" },
    { lable: "ABOUT", to: "/about" },
    { lable: "SERVICE", to: "/services" },
    { lable: "POLICY", to: "/policy" },
    { lable: "MEDIA", to: null },
    { lable: "CONTACT", to: "/contact" },
  ];
  const login = () => {
    sessionStorage.removeItem("login");
  };
  const navigate = useNavigate();
  return (
    <>
      <div className=" position-fixed top-0 w-100 z-3 bg-orange ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="ms-4 d-xl-block d-none">
            <div className="d-flex align-items-center w-100">
              <div
                className="py-3"
                style={{
                  width: "90px",
                  cursor: "pointer",
                }}
                href="#"
              >
                <img
                  src={logo}
                  className="img-fluid"
                  onClick={() => navigate("/")}
                  alt="logo"
                />
              </div>
              <div className="ms-2 text-white fs-2 fw-bold text-nowrap">
                Easy Gold Fincorp
                <div
                  className="fs-12 "
                  style={{ letterSpacing: "3px", marginTop: "-5px" }}
                >
                  "LEASE YOUR GOLD WITH EASY"
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <div className="mx-4">
              <div className="d-none d-sm-flex text-end justify-content-end  fw-medium align-items-center pt-1 pb-2">
                <div>
                  <i
                    className="fa-brands fa-whatsapp fs-24 me-1 p-1"
                    style={{ color: "#fff" }}
                  ></i>
                </div>
                <a href="tel:+91-74 9002 8003 " className="text-white fs-14">
                  WhatsApp : +91-74 9002 8003
                </a>
              </div>
            </div>
            <nav className="navbar navbar-expand-md d-md-flex justify-content-md-between align-items-md-center flex-nowrap">
              <button
                className="navbar-toggler border-0 mx-sm-3 fs-2 text-white "
                type="button"
                onClick={() => setToggle("")}
                data-bs-toggle="collapse"
                data-bs-target="#mobileMenu"
              >
                <i className="fa-solid fa-bars"></i>
              </button>
              <div className="ms-2 text-white fs-3 fw-bold text-nowrap d-md-none">
                Easy Gold Fincorp
                <div
                  className="fs-11 "
                  style={{ letterSpacing: "1px", marginTop: "-5px" }}
                >
                  "LEASE YOUR GOLD WITH EASY"
                </div>
              </div>
              <div className="d-xl-none me-2">
                <a className="p-sm-2 pb-3" href="#">
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid"
                    style={{ maxWidth: "90px", maxHeight: "90px" }}
                  />
                </a>
              </div>
              <div className="collapse d-none d-xl-block navbar-collapse">
                <div className="ms-auto ">
                  <div>
                    <ul className="navbar-nav ">
                      {navText.map((val, ind) => (
                        <NavLink to={val.to} key={ind}>
                          <li key={ind} className="nav-item">
                            <div
                              className="nav-link active px-2 header-hover text-white"
                              aria-current="page"
                              href="#"
                              style={{ borderRight: "2px dotted #BFBFBF" }}
                            >
                              <div>{val.lable}</div>
                            </div>
                          </li>
                        </NavLink>
                      ))}
                      <li>
                        <button
                          onClick={login}
                          className="btn btn-primary fw-semibold red mx-2 bg-white border-0 text-black"
                        >
                          <i class="fa-regular fa-user me-1 fw-semibold fs-18"></i>
                          Login
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <li>
                <button className="btn btn-primary fw-semibold fs-20 d-md-none red mx-2 bg-white border-0 text-black">
                  <i class="fa-regular fa-user fw-semibold me-1 "></i>
                  Login
                </button>
              </li> */}
            </nav>
          </div>
        </div>

        <div
          id="mobileMenu"
          className={`collapse navbar-collapse bg-white d-md-none ${toggle}`}
        >
          <ul>
            {navText.map((val, ind) => (
              <NavLink to={val.to} key={ind}>
                <li
                  key={ind}
                  onClick={() => setToggle("hide")}
                  className="header-hover p-3 border-bottom fw-bold text-orange"
                >
                  <span>{val.lable}</span>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
