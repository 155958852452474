import React from 'react'
import galary1 from "../../assets/images/media/galary-1.jpg"
import galary2 from "../../assets/images/media/galary-2.jpg"
import galary3 from "../../assets/images/media/galary-3.jpg"
import galary4 from "../../assets/images/media/galary-4.jpg"

const MediaHead = () => {

    const images = [galary1,galary2,galary3,galary4]
  return (
    <>
      <div className="container top-space">
        <div className="mb-5">
          <h2 className="fw-bold text-center">IMAGE GALLERY</h2>
        </div>
        <div className="row" data-aos="fade-down">
          {images.map((value, index) => (
            <div className="col-sm-6" key={index}>
              <div>
                <img src={value} className="img-fluid mb-4" alt={value} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MediaHead