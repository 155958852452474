import React from "react";
import Hero from "../../components/global/Hero";
import heroImg from "../../assets/images/media/heroBackground.jpg";
import MediaHead from "../../components/media/MediaHead";
import Subscribe from "../../components/global/Subscribe";

const Media = () => {
  return (
    <>
      <div style={{ marginTop: "110px" }}>
        <Hero
          bGSrc={heroImg}
          line1={"IMAGE GALLARY   "}
          line2={"OUR"}
          line3={"STORY IN PICTURES"}
        />
        <MediaHead />
        <Subscribe />
      </div>
    </>
  );
};

export default Media;
