import React from "react";
import { ServicesHead } from "../../components/services/ServicesHead";
import { ServicesCard } from "../../components/services/ServicesCard";
import { ServicesChargesTable } from "../../components/services/ServicesChargesTable";
import "../../assets/style/services.css";
import { ServicesTabs } from "../../components/services/ServicesTabs";
import { ServicesGoldLoan } from "../../components/services/ServicesGoldLoan";
import { ServicesSlider } from "../../components/services/ServicesSlider";
import { ServicesGrid } from "../../components/services/ServicesGrid";
import GoldCalc from "../../components/global/GoldCalc";
import Faqs from "../../components/global/Faqs";
import ProvidedServices from "../../components/global/ProvidedServices";
import Subscribe from "../../components/global/Subscribe";
import Schemes from "../../components/global/Schemes";

export const Services = () => {
  const itemData = [
    {
      title: "Who is eligible to avail a Gold Loan from Easy Gold Fincorp?",
      descripition:
        "  Any major Indian citizen is eligible to avail a gold loan from us. All they are required to do is provide the required documents along with the gold ornaments.If you wish to apply for a gold loan with us, visit our nearest branch by searching for ‘Easy Gold Fincorp gold loan near me’ on the web",
    },
    {
      title:
        "  What type of security do I need to provide to Easy Gold Fincorp? Do I need a guarantor?",
      descripition:
        " The only security that is needed from the end of the customer to avail our gold loan is the gold they pledge. There is no requirement for an external/third party guarantor for the same. The loan gets sanctioned against the gold pledged.",
    },
    {
      title:
        "What is the minimum/maximum tenure of the gold loan from Easy Gold Fincorp?",
      descripition:
        " There is no minimum tenure of gold loan. However interest is charged for minimum period of 7/15 days depending upon the gold loan scheme chosen.",
    },
    {
      title:
        "What type of gold can I pledge to avail the gold loan from Easy Gold Fincorp?",
      descripition:
        "You can pledge any type of gold ornaments/gold jewellery with us. This includes items like rings, necklaces, watches, bracelets, 'Kardahs,' pendants, 'Polki' sets, and more. You can always consult our executives at your nearest Easy Gold Fincorp branch for the exhaustive list.",
    },
    {
      title:
        "How can I repay my loan at Easy Gold Fincorp? What different options are available for the repayment?",
      descripition:
        "  You can repay your loan at any of our branches by cheque, cash, or debit cards (please note that demand drafts and credit cards are not accepted). You can even repay your loan against gold online by using Easy Gold Fincorp mobile application or Online Webpay facility. To visit our Online Webpay facility, click here.",
    },
    {
      title:
        " Where do I repay my loan from Easy Gold Fincorp? Can partial repayment be made towards the gold loan?",
      descripition:
        " The loan can be repaid at any Easy Gold Fincorp branch, no matter the location. The CBS system of Easy Gold Fincorp is implemented in every branch and lets customers repay their loan at any particular branch. But, it is important to note that the final collection of the gold needs to be done from the initial branch where it was pledged.",
    },
    {
      title:
        "How and when can I get back the pledged gold jewellery/ornaments from Easy Gold Fincorp?",
      descripition:
        "You will receive back the gold which you pledge with Easy Gold Fincorp after you repay the loan and the complete amount. If you fail to repay the loan, then Easy Gold Fincorp Ltd will reluctantly auction off the same. The borrowers will receive periodical reminders for payment of overdue interest and after the tenure of the loan, a final Notice before the auction.",
    },
    {
      title: " What is the minimum limit on Easy Gold Fincorp gold loan?",
      descripition:
        "The minimum amount of the gold loan range by Easy Gold Fincorp is ₹1500.",
    },
    {
      title:
        " Is my Gold jewellery/ornaments safe with Easy Gold Fincorp Ltd.?",
      descripition:
        "At Easy Gold Fincorp, we are well aware of the customer’s sentiment attached to the ornaments. Thus, we have put in place specialized safe rooms as well as CCTV cameras for the purpose of round-the-clock surveillance in our every branch. We enter the gold items pledged with Easy Gold Fincorp Ltd. into our systems and every submission is locked away in a packaging that is tamper-proof. So, you can remain worry free and avail a gold loan in an easy process from Easy Gold Fincorp.",
    },
    {
      title: "How can I apply for a Gold Loan with Easy Gold Fincorp Ltd.?",
      descripition:
        " If you wish to apply for a gold loan with us, you can visit any of our branches and talk to a customer care executive. The executive will assist you in filling out a KYC form for Gold Loan and provide you guidance for the process that follows.",
    },
    {
      title: "How much can I expect for my gold from Easy Gold Fincorp?",
      descripition:
        " This is dependent on the purity of gold in the ornaments and the rate of gold on that date. So, for this, please visit your nearest Easy Gold Fincorp Branch. To locate a branch of Easy Gold Fincorp near you, simply search ‘Easy Gold Fincorp gold loan near me’ on the internet.",
    },
    {
      title:
        "I need some more information, how can I contact Easy Gold Fincorp Ltd.?",
      descripition:
        "You can click here for contact us page or call at 1800 313 1212. You can also voice any concerns that you may have on our Twitter handle and Facebook page",
    },
    {
      title:
        " Can gold loan be prepaid or foreclosed? Are there any prepayment charges?",
      descripition:
        " You can pay the loan amount before the completion of your loan tenure by paying the interest, without having to pay any foreclosure charges or prepayment penalties.",
    },
    {
      title: "ow do you ensure the safety of the gold I pledge?",
      descripition:
        " Easy Gold Fincorp understands the emotional attachment that customers have to their jewellery. As a result, we have installed specialised safe rooms as well as CCTV cameras in each of our branches for round-the-clock surveillance. We enter the gold goods committed with Easy Gold Fincorp Ltd. into our systems, and each submission is stored in tamper-proof packaging. Hence, you may relax and get a gold loan from Easy Gold Fincorp in a simple method.",
    },
    {
      title:
        "Is it mandatory to have a co-applicant when applying for a Easy Gold Fincorp Loan Against Gold?",
      descripition:
        "  No, a co-applicant is not required in order to obtain the Easy Gold Fincorp gold loan. In order to proceed with the registration, you must give all of the basic information for the gold loan as specified by the company's terms and conditions. This comprises declarations and commitments made by the borrower and confirmed by him, as well as any additional papers requested by the organisation.",
    },
  ];

  const data = (
    <div className={`accordion-item`}>
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed fs-16 fw-semibold accordionhead px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseOne"
          aria-expanded="true"
          aria-controls="flush-collapseOne"
        >
          What are the benefits of taking a Gold Loan from Easy Gold Fincorp
          Ltd.?
        </button>
      </h2>
      <div
        id="flush-collapseOne"
        className="accordion-collapse show collapse"
        data-bs-parent="#accordionFlushExample"
      >
        <div className="accordion-body fs-16 black">
          Let’s take a close look at the advantages of availing a gold loan from
          Easy Gold Fincorp, the company that offers the best gold loans in the
          country:
        </div>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">Fast loan disbursal</span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">Customer oriented Services</span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">Minimal documentation</span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">In-house gold evaluation</span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">
            Get minimum loan amount of ₹1500 and no maximum limit
          </span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">Pre-payment option-with no penalty</span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">
            Strong rooms put in place for providing safe custody of gold
            ornaments
          </span>
        </p>
        <p className="mb-3">
          <span className="px-2 lightgray">
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span className="lightgray">
            Prompt customer service within a shorter response time
          </span>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <div style={{ marginTop: "150px" }}>
        <ServicesHead />
        <ServicesCard />
        <GoldCalc />
        <Schemes />
        {/* <ServicesChargesTable /> */}
        <ServicesTabs />
        <ServicesGoldLoan />
        {/* <Faqs itemData={itemData} title={"FAQs"} data={data} /> */}
        {/* <ServicesSlider /> */}
        {/* <ServicesGrid /> */}
        <ProvidedServices />
        <Subscribe />
      </div>
    </>
  );
};
