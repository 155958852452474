import React from "react";

const AboutSection = () => {
  return (
    <>
      <div className="top-space">
        <div className="container">
          <div className=" py-5">
            <h1
              className="text-uppercase text-center fw-semibold mb-4"
              style={{ fontSize: "33px" }}
            >
              about us
            </h1>
            <div data-aos="fade-down">
              <p
                style={{ textAlign: "justify", fontWeight: "500" }}
                className="fs-16 mb-4"
              >
                At Easy Gold Fincorp, we are dedicated to providing innovative
                financial solutions tailored to meet your needs. With a
                commitment to excellence and customer satisfaction, we strive to
                deliver reliable services that empower individuals and
                businesses alike. Our experienced team is here to assist you
                every step of the way, ensuring transparency, security, and
                efficiency in all our endeavors. Trust Easy Gold Fincorp for
                your financial needs and embark on a journey towards prosperity
                today.
              </p>
              <p
                style={{ textAlign: "justify", fontWeight: "500" }}
                className="fs-16"
              >
                At Easy Gold Fincorp, our mission is simple: to be your trusted
                partner in financial success. With a focus on integrity,
                reliability, and personalized service, we strive to exceed your
                expectations at every turn. Backed by a team of experts and a
                commitment to innovation, we're here to help you achieve your
                goals and secure your financial future. Discover the difference
                with Easy Gold Fincorp and let us be your guide on the path to
                prosperity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
