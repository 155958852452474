import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/style/home.css";
import OwlCarousel from "react-owl-carousel";
import goldloanicon1 from "../../assets/images/home/services_provider/img-1.png";
import goldloanicon2 from "../../assets/images/home/services_provider/img-2.png";
import goldloanicon3 from "../../assets/images/home/services_provider/img-3.png";
import goldloanicon4 from "../../assets/images/home/services_provider/img-4.png";
import goldloanicon5 from "../../assets/images/home/services_provider/img-5.png";
import goldloanicon6 from "../../assets/images/home/services_provider/img-6.png";

const ServiceProvide = () => {
  // const options = {
  //   navText: [
  //     '<i className="fa-solid fa-chevron-left"></i>',
  //     '<i className="fa-solid fa-chevron-right"></i>',
  //   ],
  //   loop: false,
  //   margin: 10,
  //   nav: true,
  //   autoplay: false,
  //   autoplaytimeout: 3000,
  //   dots: false,
  //   responsive: {
  //     0: {
  //       items: 2,
  //       nav: false,
  //     },
  //     600: {
  //       items: 3,
  //       nav: false,
  //     },
  //     800: {
  //       items: 4,
  //       nav: false,
  //     },
  //     1000: {
  //       items: 5,
  //       nav: false,
  //     },
  //     1200: {
  //       items: 7,
  //     },
  //   },
  // };

  const ServiceProvideSection = [{ img: goldloanicon1, text: "#GOLD LOAN" }];
  return (
    <>
      <div className="container">
        <h2 className="fw-bold text-center">Gold Loan</h2>
        <div className="mt-4">
          {/* <OwlCarousel className="owl-theme" {...options}> */}
          {/* {ServiceProvideSection.map((item, index) => (
            <div className="item text-center serviceProvide1 p-4" key={index}>
              <div className="serviceIcon text-center w-100">
                <img
                  src={item.img}
                  alt={item.img}
                  className="object-fit-contain"
                />
              </div>
              <div className="fw-bold text-danger">{item.text}</div>
            </div>
          ))} */}
          {/* </OwlCarousel> */}
          <div>
            <p className="text-center">
              An ideal solution for all your financial needs, be it a medical
              emergency, business expansion, wedding expenses or anything else.
              Plus, the interest rates are attractive and comparatively lower to
              help you raise funds instantly. Convenience is one of the highest
              priorities, hence Easy Gold Fincorp offers.
            </p>
          </div>
          <div className="mt-3">
            <p className="text-center">
              Easy Gold Fincorp team ensure that
              <span className="header-data text-danger text-body-secondary border-0">
                gold loan
              </span>
              process is hassle-free for all our customers. you can apply and
              visit our branch
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceProvide;
