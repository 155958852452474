import React from "react";





export const ServicesHead = () => {


  return (
    <>
      <div className="container" style={{ marginTop: "130px" }}>
        <div>
          <div className="text-center fs-2 fw-bold">GOLD LOAN</div>
          <div className="overflow-x-auto text-nowrap">
            <ul className="d-flex justify-content-lg-center align-items-center py-4 ">
              <li className="header-data">Calculator</li>
              <li className="header-data">Process</li>
              <li className="header-data">Interest Rate</li>
              <li className="header-data">Eligibility</li>
              <li className="header-data">Jewel Loan</li>
              <li className="header-data">Gold Loan Per Gram</li>
              <li className="header-data border-0">Schemes</li>
            </ul>
          </div>
          <hr className="m-0 w-100 z-n1" />
          <div>
            <div className="my-4">
              <p className="text-center">
                Discover the convenience of our
                <span className="header-data text-body-secondary border-0">
                  gold loan service,
                </span>
                akin to a loan against gold, where customers can leverage their
                gold ornaments such as gold jewelry as collateral. Provided by
                Easy gold fincorp, this secured loan offers a swift and
                straightforward solution to meet your financial requirements.
                With competitive rates and hassle-free processing, our gold loan
                service presents a practical alternative to traditional banking
                channels, ensuring quick access to funds when you need them
                most.
              </p>
            </div>
            <div className="my-4">
              <p className="text-center">
                When you search for
                <span className="header-data text-body-secondary border-0">
                  'gold loan near me,'
                </span>
                think of us as your premier destination. At Easy gold fincorp,
                we offer the best
              </p>
            </div>
            <div className="my-4">
              <p className="text-center">
                gold loan solutions with the lowest interest rates, catering to
                a diverse range of needs. Whether it's for personal expenses,
                business ventures, or emergencies, our gold loan service is
                designed to provide quick and reliable financial assistance
                whenever you need it.
              </p>
            </div>
            <div className="my-4">
              <p className="text-center">
                you toget a gold loanat the comfort of your home in 4 easy steps
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
