import React, { useState } from "react";
import logo_1 from "../../assets/images/home/schemes/logo-1.png";
import logo_2 from "../../assets/images/home/schemes/logo-2.png";
import logo_3 from "../../assets/images/home/schemes/logo-3.png";
import logo_4 from "../../assets/images/home/schemes/logo-4.png";
import logo_5 from "../../assets/images/home/schemes/logo-5.png";
import logo_6 from "../../assets/images/home/schemes/logo-6.png";
import logo_7 from "../../assets/images/home/schemes/logo-7.png";
import logo_8 from "../../assets/images/home/schemes/logo-8.png";
import logo_9 from "../../assets/images/home/schemes/logo-9.png";
import logo_10 from "../../assets/images/home/schemes/logo-10.png";
import background from "../../assets/images/home/schemes/background.png";

const ServicesGoldLoanSchemes = () => {
  // const [show, setShow] = useState("none");
  // const [show1, setShow1] = useState("none");
  // const [show2, setShow2] = useState("none");
  // const [show3, setShow3] = useState("none");
  const [items, setItems] = useState({});
  const SchemesBoxes = [
    {
      img: logo_1,
      boxTitle: "ONE PERCENT LOAN",
      scheme1: "Loan Amount: ₹15,000 to ₹1,00,000",
      scheme2: "Tenure: 6 months",
      scheme3:
        "Scheme with the lowest rate of interest if 100% interest is paid monthly",
      // key: show,
      // oncli: setShow,
    },
    {
      img: logo_2,
      boxTitle: "ULTIMATE LOAN",
      scheme1: "Loan starting from ₹15,000 and no maximum limit",
      scheme2: "Tenure: 6 months",
      scheme3:
        "Rate of interest is 18% p.a. with 2% rebate if 100% interest is paid monthly",
      // key: show1,
      // oncli: setShow1,
    },
    {
      img: logo_6,
      boxTitle: "HIGH VALUE PLUS LOAN",
      scheme1: "Loan starting from ₹50,000 and no maximum limit",
      scheme2: "Tenure: 6 months",
      scheme3: "Interest rate as on date as low as 15% p.a.",
      scheme4: "Maximum Loan Value offered",
      // key: show2,
      // oncli: setShow2,
    },
    {
      img: logo_7,
      boxTitle: "HIGH VALUE LOAN",
      scheme1: "Loan Amount: ₹2 Lakh to ₹5 crores",
      scheme2: "Tenure: 6 months",
      scheme3: "Interest rates as low as 13.8% p.a.",
      scheme4: "Maximum Loan Value offered",
      // key: show3,
      // oncli: setShow3,
    },
  ];
  return (
    <>
      <div style={{ paddingTop: "60px" }} className="per">
        <div style={{ background: "#f7f7f7" }}>
          <div className="container py-5">
            <div>
              <h3 className="fw-bold text-center">GOLD LOAN SCHEMES</h3>
            </div>
            <div className="row g-4 py-3 ">
              {SchemesBoxes.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-sm-6  col-12 mt-5 "
                  key={index}
                  data-aos="fade-up"
                >
                  <div className="schemesBox bg-white p-4 d-flex flex-column justify-content-between schemesBoxs">
                    <div
                      style={{
                        width: "70px",
                        height: "65px",
                        transform: "translate(-10%,-70%)",
                        borderRadius: "10px",
                        background: "#f7f7f7",
                      }}
                      className="border border-2 p-2"
                    >
                      <img src={item.img} alt={item.img} />
                    </div>
                    <div
                      className="fw-semibold schemeBoxTitle"
                      style={{ marginTop: "-20px" }}
                    >
                      {" "}
                      {item.boxTitle}
                    </div>
                    <div
                      className={`mt-3`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setItems(item);
                        // item.oncli("block");
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Learn More
                    </div>

                    {/* <div className={`d-${item.key}`}>
                      <div className="d-flex justify-content-start fw-medium fs-15">
                        <span
                          className="black fs-13"
                          style={{ marginRight: "10px", display: "block" }}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        {item.scheme1}
                      </div>
                      <div className="d-flex justify-content-start fw-medium fs-15">
                        <span
                          className="black fs-13"
                          style={{ marginRight: "10px", display: "block" }}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        {item.scheme2}
                      </div>
                      <div className="d-flex justify-content-start fw-medium fs-15">
                        <span
                          className="black fs-13"
                          style={{ marginRight: "10px", display: "block" }}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        {item.scheme3}
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title fs-24 fw-bold"
                        id="exampleModalLabel"
                      >
                        {items.boxTitle}
                      </div>
                      <div className="ms-auto ">
                        <button
                          type="button"
                          className="btn-close closeButton"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="d-flex justify-content-start fw-medium fs-15">
                        <span
                          className="black fs-13 mb-3"
                          style={{ marginRight: "10px", display: "block" }}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        {items.scheme1}
                      </div>
                      <div className="d-flex justify-content-start fw-medium fs-15">
                        <span
                          className="black fs-13 mb-3"
                          style={{ marginRight: "10px", display: "block" }}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        {items.scheme2}
                      </div>
                      <div className="d-flex justify-content-start fw-medium fs-15">
                        <span
                          className="black fs-13 mb-3"
                          style={{ marginRight: "10px", display: "block" }}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                        {items.scheme3}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesGoldLoanSchemes;
