import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/style/home.css";
import sliderImg1 from "../../assets/images/home/silderImg1.jpg";
import sliderImg2 from "../../assets/images/home/silderImg2.jpg";
import sliderImg3 from "../../assets/images/home/silderImg3.jpg";
import sliderImg4 from "../../assets/images/home/silderImg4.jpg";

const   Herosection = () => {
  const options = {
    loop: true,
    stagePadding: 140,
    margin: 20,
    nav: false,
    autoplay: true,
    dots: true,
    responsive: {
      0: {
        stagePadding: 10,
        items: 1,
      },
      600: {
        stagePadding: 40,
        items: 1,
      },
      1000: {
        stagePadding: 80,
        items: 1,
      },
    },
  };

  const sliderArray = [sliderImg1, sliderImg2, sliderImg3, sliderImg4];
  return (
    <>
      <div className="py-sm-4 py-xl-0">
        <section id="heroSection" className="py-sm-5 py-3">
          <OwlCarousel className="owl-theme mt-4" {...options}>
            {sliderArray.map((data, index) => (
              <div className="item sliderImg" key={index}>
                <img src={data} alt={data} className="img-fluid " />
              </div>
            ))}
          </OwlCarousel>
        </section>
      </div>
    </>
  );
};

export default Herosection;
