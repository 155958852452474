import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Herosection from "../../components/Home/Herosection";
import ServiceProvide from "../../components/Home/ServiceProvide";
import WhyChooseUS from "../../components/Home/WhyChooseUS";
import Schemes from "../../components/global/Schemes";
import GoldCalc from "../../components/global/GoldCalc";
import NewsMedia from "../../components/Home/NewsMedia";
import Faqs from "../../components/global/Faqs";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/images/header/logo.png";
import { useFormik } from "formik";
import Loction from "../../Countrystatecity.json";
import axios from "axios";

const Home = () => {
  const [show, setShow] = useState(false);
  const value = sessionStorage.getItem("login");

  const handleClose = () => {
    sessionStorage.setItem("login", true);
    setShow(false);
  };
  console.log("value", value);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!value) {
      handleShow();
    }
  }, [value]);

  const itemData = [
    {
      title: "What services does Easy Gold Fincorp offer?",
      descripition:
        "As part of our core business, we provide business loans secured by gold jewellery or gold loans. We primarily cater to individuals who possess gold jewellery but cannot access formal credit within a reasonable time, or for whom credit may not be available at all. We help our customers meet unanticipated or other short-term liquidity requirements.",
    },
    {
      title: "Why should I choose The Easy Gold Fincorp?",
      descripition:
        "The Easy Gold Fincorp has been transforming the lives of millions of Indians across the country, giving wings to their dreams, and creating successful realities.",
    },
    {
      title: "What services does Easy Gold Fincorp offer?",
      descripition:
        "You can fill in the information through the online form to avail Gold Loan from Easy Gold Fincorp, our team will call you to understand the requirement and guide you further.",
    },
  ];
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches("^[A-Za-zs.' '-'_]+$", "Name is not valid"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required")
      .matches(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
        "Invalid email format"
      ),
    contact: Yup.string()
      .required("Contact is required")
      .matches(/^[0-9]+$/, "Invalid Contact Format")
      .max(10, "Contact number must be exactly 10 digits")
      .min(10, "Contact number must be exactly 10 digits"),
    pincode: Yup.string()
      .required("pincode is required")
      .matches(/^[0-9]+$/, "Invalid pincode Format")
      .max(6)
      .min(6),
    country: Yup.string().required("country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
  });

  const Formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      pincode: "",
      country: "",
      state: "",
      city: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      console.log(values, "value");

      await axios
        .post(
          `https://easygoldfincorp-reports.onrender.com/api/inquiry`,
          values
        )
        .then((res) => console.log(res, "res"))
        .catch((err) => console.log(err, "err"));
      handleClose();
      action.resetForm();
    },
  });

  return (
    <>
      <Modal show={show} centered>
        <div>
          <div className="modal-header d-block">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClose()}
              ></button>
            </div>
            <div
              className="d-flex align-items-center mb-3 pb-1"
              style={{ marginTop: "-7px" }}
            >
              <div style={{ maxWidth: "100px" }}>
                <img src={logo} alt={logo} className="img-fluid" />
              </div>
              <span className="h1 fw-bold ms-md-4 ms-2 fs-3">
                Easy Gold Fincorp
              </span>
            </div>
          </div>
          <div className="modal-body">
            <form onSubmit={Formik.handleSubmit} onReset={Formik.handleReset}>
              <div className="my-4">
                <input
                  type="text"
                  placeholder="Name*"
                  className="outline-none p-2 border-0 border-bottom border-dark-subtle fw-medium w-100"
                  name="name"
                  value={Formik.values.name}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                {Formik.errors.name && Formik.touched.name ? (
                  <div className="text-danger">{Formik.errors.name}</div>
                ) : null}
              </div>

              <div className="my-4">
                <input
                  type="text"
                  placeholder="Email*"
                  className="outline-none p-2 border-0 border-bottom border-dark-subtle fw-medium w-100"
                  name="email"
                  value={Formik.values.email}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                {Formik.errors.email && Formik.touched.email ? (
                  <div className="text-danger">{Formik.errors.email}</div>
                ) : null}
              </div>
              <div className="my-4">
                <input
                  type="text"
                  placeholder="Contact*"
                  className="outline-none p-2 border-0 border-bottom border-dark-subtle fw-medium w-100"
                  name="contact"
                  value={Formik.values.contact}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                {Formik.errors.contact && Formik.touched.contact ? (
                  <div className="text-danger">{Formik.errors.contact}</div>
                ) : null}
              </div>
              <div className="my-4">
                <div>
                  <select
                    id="countrySelect"
                    name="country"
                    className="w-100 inputbox text-black fw-medium"
                    value={Formik.values.country}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                  >
                    <option value="" disabled hidden>
                      country
                    </option>
                    {Loction.map((country) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {Formik.errors.country && Formik.touched.country ? (
                    <div className="text-danger">{Formik.errors.country}</div>
                  ) : null}
                </div>
              </div>
              <div className="my-4">
                <div>
                  <select
                    id="countrySelect"
                    name="state"
                    className="w-100 inputbox text-black fw-medium"
                    value={Formik.values.state}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                  >
                    <option value="" disabled hidden className="fw-bold black">
                      State
                    </option>
                    {Formik.values.country &&
                      Loction.find(
                        (country) => country.name === Formik.values.country
                      )?.states.map((state) => (
                        <option key={state.id} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                  {Formik.errors.state && Formik.touched.state ? (
                    <div className="text-danger">{Formik.errors.state}</div>
                  ) : null}
                </div>
              </div>
              <div className="my-4">
                <div>
                  <select
                    id="countrySelect"
                    name="city"
                    className="w-100 inputbox text-black fw-medium"
                    value={Formik.values.city}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                  >
                    <option value="" disabled hidden>
                      City
                    </option>
                    {Formik.values.state &&
                      Loction.find(
                        (country) => country.name === Formik.values.country
                      )
                        ?.states.find(
                          (state) => state.name === Formik.values.state
                        )
                        ?.cities.map((city) => (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                  </select>
                  {Formik.errors.city && Formik.touched.city ? (
                    <div className="text-danger">{Formik.errors.city}</div>
                  ) : null}
                </div>
              </div>
              <div className="my-4">
                <input
                  type="text"
                  placeholder="Pincode*"
                  className="outline-none p-2 border-0 border-bottom border-dark-subtle fw-medium w-100"
                  name="pincode"
                  value={Formik.values.pincode}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                {Formik.errors.pincode && Formik.touched.pincode ? (
                  <div className="text-danger">{Formik.errors.pincode}</div>
                ) : null}
              </div>
              <div className="pt-1 mb-4">
                <button
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className="btn btn-dark w-100 d-block fw-semibold"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div style={{ marginTop: "80px" }}>
        <Herosection />
        <ServiceProvide />
        <WhyChooseUS />
        <Schemes />
        <GoldCalc />
        {/* <NewsMedia/> */}
        <Faqs title={"FAQs"} itemData={itemData} />
      </div>
    </>
  );
};

export default Home;
