import React from "react";

const MissionVission = () => {
  return (
    <div className="container top-space">
      <div className="text-center">
        <div className="mission">
          <h3 style={{ fontSize: "24px", fontWeight: "600" }}>Mission</h3>
          <p style={{ fontWeight: "500" }}>
            Our mission at Easy Gold Fincorp is to empower individuals and
            businesses alike with tailored financial solutions that inspire
            confidence and drive success. With a dedication to integrity,
            innovation, and personalized service, we strive to exceed
            expectations and make a positive impact on the lives of our clients
            and communities. Through transparency, reliability, and a commitment
            to excellence, we aim to be the trusted partner you can rely on for
            all your financial needs, today and in the future
          </p>
        </div>
        <div className="mission mt-3">
          <h3 style={{ fontSize: "24px", fontWeight: "600" }}>Vision</h3>
          <p style={{ fontWeight: "500" }}>
            Our vision at Easy Gold Fincorp is to revolutionize the financial
            landscape by providing accessible, innovative, and sustainable
            solutions that empower individuals and businesses to thrive.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MissionVission;
