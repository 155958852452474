import React from "react";
import logo_1 from "../../assets/images/home/whychooseUs/logo-1.svg";
import logo_2 from "../../assets/images/home/whychooseUs/logo-2.svg";
import logo_3 from "../../assets/images/home/whychooseUs/logo-3.svg";
import logo_4 from "../../assets/images/home/whychooseUs/logo-4.svg";
import logo_5 from "../../assets/images/home/whychooseUs/logo-5.svg";
import logo_6 from "../../assets/images/home/whychooseUs/logo-6.svg";
const WhyChooseUS = () => {
  const whyChooseUsboxes = [
    {
      img: logo_1,
      title: "Increase Funds",
      text: "We do not charge any extra hidden processing fees.",
    },
    {
      img: logo_2,
      title: "increase value",
      text: "The gold loan rate per gram offered by Easy Gold Fincorp is the highest in this segment.",
    },
    {
      img: logo_3,
      title: "Best Service",
      text: "Elevate your investment experience with our premier gold service, providing tailored solutions for maximizing returns and achieving financial success.",
    },
    {
      img: logo_4,
      title: "Best Security",
      text: " Your trust is our top priority. With robust security measures in place,you can rest assured that your gold assets are safe and protected throughout the loan tenure",
    },
    {
      img: logo_5,
      title: "Fast Processing",
      text: "A straightforward process doesn’t require any in-depth knowledge. The debtor has to carry the gold to be mortgaged and identity/ address proof",
    },
    {
      img: logo_6,
      title: "Deductible Intrest",
      text: "Deductible interest, a valuable financial benefit, allows eligible borrowers to reduce their taxable income by deducting the interest paid on qualifying loans, providing potential savings and financial relief.",
    },
  ];
  return (
    <>
      <div className="whyChooseUs top-space">
        <div className="container">
          <h2 className="fw-bold text-center">Why Choose Us</h2>
          <div className="row my-4 g-4">
            {whyChooseUsboxes.map((item, index) => (
              <div className="col-lg-4 col-sm-6" key={index}>
                <div className="h-100 py-4 px-2 rounded ">
                  <div className="text-center">
                    <div className="whyChooseUsImg d-inline-block">
                      <img src={item.img} alt={item.img} />
                    </div>
                  </div>
                  <h4 className="text-center fw-bold my-4">{item.title}</h4>
                  <div
                    style={{ color: "#707070", letterSpacing: "1.1px" }}
                    className="px-2 fw-medium"
                  >
                    {item.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUS;
